<template>
  <div class="yunlist" ref="list">
    <!-- 头部 -->
    <van-nav-bar
      @click-left="onClickLeft"
      title="云店专供"
      left-arrow
    />
    <!-- 列表 竖版 -->
    <div class="list_content">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-grid :gutter="10" :column-num="2" :border="false">
          <van-list
            v-model="loading"
            :finished="finished"
            @load="getGoodsList"
            :immediate-check="false"
          >
            <van-grid-item v-for="(item,index) in goods" :key="item.id" :data-index="index" :data-id="item.id" @click="details(item.id)">
              <template #icon>
                <van-image
                  :src="item.thumb"
                  :style="'width:' + (screenWidth - 30) / 2 + 'px;height:' + (screenWidth - 30) / 2 + 'px;'"
                  lazy-load
                  fit="cover"
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <div class="shuiyin">{{strId}}</div>
              </template>
              <template #text>
                <div class="goods_text van-multi-ellipsis--l2" :style="'width:'+((screenWidth-30)/2-20)+'px;'">{{item.title}}</div>
                <div class="goods_price van-ellipsis" :style="'width:'+((screenWidth-30)/2-20)+'px;'">
                  <span style="color: #C8241A;">&yen;</span> {{item.price}}
                  <span v-show="parseInt(item.sales) > 0 ? true : false">{{item.sales}}人已购买</span>
                </div>
              </template>
            </van-grid-item>
          </van-list>
          
        </van-grid>
      </van-pull-refresh>
    </div>
    <div class="kong_page" v-if="kongshow">
      <van-empty description="暂无数据" />
    </div>
    <div class="loading" v-if="finished">
      <van-divider >没有更多了</van-divider>
    </div>
  </div>
</template>
<script>
export default {
  name: 'YunList',
  data(){
    return {
      showSearchBtn: false, // 默认隐藏搜索按钮
      keywords: '', // 关键词
      currSize: 20, // 步数
      currOffset: 0, // 偏移
      isLoading: false, // 是否加载下完成
      loading: false,
      finished: false,
      nodata: false, // 是否空数据
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      uid: localStorage.getItem("DK_UID")?localStorage.getItem("DK_UID"):"", // 用户信息
      islogin: localStorage.getItem("DK_ISLOGIN")?localStorage.getItem("DK_ISLOGIN"):false, // 用户信息
      goods: [],
      groupid: 0,
      shareid: 0,
      merchid: 0,
      strId: "",
      kongshow: false,
      loadshow: false,
      iswx: false,
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage"
        ], // 必填，需要使用的JS接口列表
        timestamp: 1585098244, // 必填，生成签名的时间戳
        nonceStr: "dfbfwkm1egc", // 必填，生成签名的随机串
        signature: "d8caa8a88d588a8dc5379dbfbde5d875886baed5" // 必填，签名
      },
      sort: 0, // 0 按新品（时间）排序，1 按销量排序，2按价格升序，3按价格降序
      
    }
  },

  mounted() {
    let groupid = 0;
    groupid = this.$route.query.groupid ? parseInt(this.$route.query.groupid) : 0;
    
    this.groupid = this.$route.query.groupid ? parseInt(this.$route.query.groupid) : 0;
    this.strId = localStorage.getItem("DK_STRID");
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.loadshow = false;

    this.init();
    
  },
  activated() {
    this.$parent.getmerchname(this.merchid, "云店专供");
    if (window.isWeixin()) {
      this.iswx = true; 
      setTimeout(() => {
        this.peizhiWxConfig(); // 配置微信分享
      }, 1000);
    }
  },

  methods: {
    init() {
      this.getGoodsList();
      if (this.merchid > 0) {
        this.setmerchMber();
      }
    },

    // list 加载获取产品数据
    getGoodsList(){
      let _that = null;
      _that = this;
      _that.showSearchBtn = false
      _that.goodsloading = true;
      if (_that.currOffset == 0) {
        _that.loadshow = true;
      }
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      if ((this.keywords || this.groupid >= 0) && _that.goodsloading) {
        _that.$axios
          .post(
            _that.$store.state.domain + 'web/goods/get_yundian_list',
            _that.$qs.stringify({
              groupid: _that.groupid,
              merchid: _that.merchid,
              offset: _that.currOffset,
              size: _that.currSize,
            })
          )
          .then(function(response){
            _that.loadshow = false;
            _that.isLoading = false;
            _that.loading = false;
            _that.nodata = false;
            if (response && response.data && response.data.code == 100000) {
              if (_that.currOffset == 0) {
                _that.goods = response.data.data;
              } else {
                response.data.data.forEach(item => {
                  _that.goods.push(item);
                })
              }
              if (response.data.data.length < _that.CurrSize) {
                _that.finished = true;
              }
              _that.currOffset += response.data.data.length;
            } else {
              _that.finished = true;
              if (_that.currOffset == 0) {
                _that.nodata = true;
              }
            }
          })
          .catch(function(error){
            console.log(error);
            _that.isLoading = false;
          })
      }
    },

    
    // 点击左侧按钮
    onClickLeft(){
      this.$router.go(-1);
    },

    // 进入详情页
    details(id){
      this.$router.push({ name: "Details", query: { id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
      }
    },
    
    /* 设置分享信息 */
    onShare() {
      let _that = null;
      _that = this;
      const mid = localStorage.getItem("DK_MEMBERID"); // 分享者id
      let title = "大咖货源"; // 分享标题
      let desc = document.title; // 分享简介
      let img = ""; // 分享图片地址
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_merchNme",
          _that.$qs.stringify({
            id: _that.merchid
          })
        )
        .then(res => {
          if (res.data.merchname) {
            title = res.data.merchname;
            desc = res.data.merchname;
          }
          if (res.data.logo) {
            img = res.data.logo;
          }

          window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
            window.wx.updateAppMessageShareData({ 
              title: title, // 分享标题
              desc: document.title, // 分享描述
              link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: img, // 分享图标
              type: "link",
              success: function() {
                console.log("分享设置成功");
              }
            })
          });
        })
    },
    // 分享初始化
    peizhiWxConfig() {
      let _that = null;
      _that = this;
      /* 获取wx初始化配置 */
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_sign",
          _that.$qs.stringify({
            url: encodeURIComponent(window.location.href)
          })
        )
        .then(function(response) {
          _that.wxConfig.timestamp = response.data.timestamp;
          _that.wxConfig.nonceStr = response.data.nonceStr;
          _that.wxConfig.signature = response.data.signature;
          window.wx.config(_that.wxConfig); // wx初始化配置
          setTimeout(() => {
            _that.onShare();
          }, 100)
        })
        .catch(function(error) {
          window.getWx = _that.getWxConfig; // 微信签名过期后调用 外部JS
        });
    },

    /* 获取配置失效 */
    getWxConfig(err) {
      // 如果过期，从html JS 调用重新签名
      if (err.indexOf("invalid signature") != -1) {
        let $this = null;
        $this = this;
        this.$axios
          .post(
            $this.$store.state.domain + "web/wetch/wx_sign",
            $this.$qs.stringify({
              url: encodeURIComponent(window.location.href)
            })
          )
          .then(function(response) {
            this.peizhiWxConfig(); // 配置微信分享
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },

    /* 下拉刷新 */
    onRefresh() {
      this.currOffset = 0;
      this.isLoading = true;
      this.getGoodsList();
    }

  }
}
</script>
<style lang="less">
.yunlist{
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #ededed;
  padding-bottom: 60px;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .van-list {
    display: flex;
    flex-wrap: wrap;
    .van-list__loading {
      width: 100%;
    }
  }
  // 列表
  .list_content,
  .list_twocontent{
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
  }
  // 双排竖版
  .list_content{
    .van-grid-item__content{
      padding: 0;
      border-radius: 8px;
      overflow: hidden;
      .van-image{
        display: block;
      }
      .goods_text{
        height: 40px;
        line-height: 20px;
        padding: 5px 10px 0;
        font-size: 14px;
        color: #333;
        text-align: left;
      }
      .goods_price{
        padding: 5px 10px 10px;
        font-size: 16px;
        color: #C8241A;
        text-align: left;
        span{
          color: #999;
          font-size: 12px;
        }
      }
    }
  }

  // 水印
  .shuiyin{
    padding: 2px;
    background-color:#fff;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #808080;
    opacity: 0.5;
    font-size: 14px;
  }
  .loading {
    width: 100%;
    height: 100%;
    background-color: #EDEDED;
  }
  .red {
    color: #ff0000;
  }
}
</style>
